import { ref } from 'vue'
import { defineStore } from 'pinia'
import { sendMagicLink, checkMagicLink, authMe } from '@/lib/requests'
import { useRouter } from 'vue-router'

function clearAuth() {
  localStorage.removeItem('authToken')
}

export const useAuthStore = defineStore('auth', () => {
  const router = useRouter()
  const isLoggedIn = ref(false)
  const errorMessage = ref(null)
  const checkMeError = ref(null)

  async function checkAuthMe(auth) {
    const response = await authMe(auth)
    // console.log('response checkAuthMe', response)
    if (!response.ok) {
      isLoggedIn.value = false
      clearAuth()
      // console.log('checkMe error', response)
      checkMeError.value = true
      // console.log('checkMeError', checkMeError.value)
      router.push('/')
      return
    }
    isLoggedIn.value = true
    // console.log('checkAuthMe', response)
    return response
  }

  async function sendLink(email) {
    return await sendMagicLink({ email: email })
  }

  async function checkToken(token) {
    const response = await checkMagicLink({ token: token })
    // console.log('response checkToken', response)
    const data = await response.json()

    if (!response.ok) {
      errorMessage.value = data
      // console.log('errorMessage', errorMessage.value)
      isLoggedIn.value = false
      clearAuth()
      return
    }
    if (response.ok) {
      localStorage.setItem('authToken', data.authToken)
      // console.log('authToken', localStorage.getItem('authToken'))
      isLoggedIn.value = true
      router.push('/dashboard')
    }
  }

  async function logout() {
    clearAuth()
    isLoggedIn.value = false
    router.push('/')
  }

  return {
    checkAuthMe,
    sendLink,
    checkToken,
    logout,
    errorMessage,
    isLoggedIn,
    checkMeError,
  }
})
