<script setup>
import { ref, onBeforeMount } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { Dialog, DialogPanel } from '@headlessui/vue'
import { Bars3Icon, XMarkIcon } from '@heroicons/vue/24/outline'
import { useRouter } from 'vue-router'

const router = useRouter()

const authStore = useAuthStore()

const navigation = [
  { name: 'Dashboard', to: '/dashboard' },
  { name: 'Raporty z degustacji', to: '/tasting-reports' },
]
const mobileMenuOpen = ref(false)

function goToDashboard() {
  if (authStore.isLoggedIn) {
    router.push('/dashboard')
  } else {
    router.push('/')
  }
}
</script>
<template>
  <header class="bg-white">
    <nav
      class="flex items-center justify-between p-6 gap-x-10 lg:px-10"
      aria-label="Global"
    >
      <div>
        <RouterLink @click="goToDashboard" to="/">
          <span class="sr-only">M&P Pavlina</span>
          <img class="h-4 w-auto" src="@/assets/logo.svg" alt="" />
        </RouterLink>
      </div>
      <div v-if="authStore.isLoggedIn" class="flex lg:hidden">
        <button
          type="button"
          class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          @click="mobileMenuOpen = true"
        >
          <span class="sr-only">Open main menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div v-if="authStore.isLoggedIn" class="hidden lg:flex lg:gap-x-8">
        <RouterLink
          v-for="item in navigation"
          :key="item.name"
          :to="item.to"
          class="text-sm font-semibold leading-6 text-gray-900"
          >{{ item.name }}</RouterLink
        >
      </div>
      <div
        v-if="authStore.isLoggedIn"
        class="hidden lg:flex lg:flex-1 lg:justify-end"
      >
        <button
          @click="authStore.logout"
          class="text-sm font-semibold leading-6 text-gray-900"
        >
          Wyloguj
        </button>
      </div>
    </nav>
    <Dialog
      class="lg:hidden"
      @close="mobileMenuOpen = false"
      :open="mobileMenuOpen"
      v-if="authStore.isLoggedIn"
    >
      <div class="fixed inset-0 z-10" />
      <DialogPanel
        class="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10"
      >
        <div class="flex items-center justify-between">
          <a href="#" class="-m-1.5 p-1.5">
            <span class="sr-only">M&P Pavlina</span>
            <img class="h-4 w-auto" src="@/assets/logo.svg" alt="" />
          </a>
          <button
            type="button"
            class="-m-2.5 rounded-md p-2.5 text-gray-700"
            @click="mobileMenuOpen = false"
          >
            <span class="sr-only">Close menu</span>
            <XMarkIcon class="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div class="mt-6 flow-root">
          <div class="-my-6 divide-y divide-gray-500/10">
            <div class="space-y-2 py-6">
              <a
                v-for="item in navigation"
                :key="item.name"
                :href="item.href"
                class="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >{{ item.name }}</a
              >
            </div>
            <div class="py-6">
              <a
                href="#"
                class="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                >Log in</a
              >
            </div>
          </div>
        </div>
      </DialogPanel>
    </Dialog>
  </header>
</template>
