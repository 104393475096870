import './assets/index.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
// import * as Sentry from '@sentry/vue'

const app = createApp(App)

// Sentry.init({
//   app,
//   dsn: 'https://54bb3757417206884c871870ea388fea@o4508177950572544.ingest.de.sentry.io/4508177951752272',
//   integrations: [],
// })

app.use(createPinia())
app.use(router)
app.mount('#app')
