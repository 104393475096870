<script setup>
import { ref, computed, watchEffect, onMounted } from 'vue'
import { useAuthStore } from '@/stores/auth'
import { useRouter } from 'vue-router'

const router = useRouter()
const email = ref('')
const isDisabled = ref(false)
const message = ref('')
const showMessage = ref(false)
const authStore = useAuthStore()
const errorMessage = ref('')

const emailValidation = computed(() => {
  return email.value.includes('@wina-mp.pl')
})

async function sendLink(email) {
  const response = await authStore.sendLink(email)
  if (!response.ok) {
    const data = await response.json()
    errorMessage.value = data.message

    setTimeout(() => {
      errorMessage.value = ''
    }, 6000)
  }

  // isDisabled.value = true

  if (response.ok) {
    showMessage.value = true
    setTimeout(() => {
      isDisabled.value = false
    }, 1000)
    setTimeout(() => {
      showMessage.value = false
    }, 6000)
  }
}
onMounted(() => {
  if (localStorage.getItem('authToken')) {
    authStore.checkAuthMe(localStorage.getItem('authToken'))
    router.push('/dashboard')
  }
})
</script>
<template>
  <div
    class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8"
  >
    <div class="sm:mx-auto sm:w-full sm:max-w-sm">
      <!-- <img
        class="mx-auto h-6 w-auto"
        src="@/assets/logo.svg"
        alt="M&P Pavlina"
      /> -->
      <h2
        class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900"
      >
        Logowanie
      </h2>
    </div>

    <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm space-y-6">
      <div>
        <div class="mt-2">
          <input
            v-model="email"
            id="email"
            name="email"
            type="email"
            placeholder="E-mail w domenie @wina-mp.pl"
            required="true"
            class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div v-if="errorMessage" class="text-red-500 text-sm mt-2">
          <p>{{ errorMessage }}</p>
        </div>
        <div v-if="showMessage" class="text-gray-500 text-sm mt-2">
          <p>Link został wysłany. Sprawdź swoją pocztę.</p>
        </div>
      </div>

      <div>
        <button
          @click="sendLink(email)"
          :disabled="!emailValidation || isDisabled"
          :class="
            isDisabled || !emailValidation
              ? 'flex w-full justify-center rounded-md bg-gray-400 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm'
              : 'flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          "
        >
          Wyślij link do logowania
        </button>
      </div>
    </div>
  </div>
</template>
