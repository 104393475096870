import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from '@/stores/auth'
import LoginView from '@/views/LoginView.vue'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'home',
      component: LoginView,
    },
    {
      path: '/verify',
      name: 'verify',
      component: () => import('@/views/VerifyTokenView.vue'),
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: () => import('@/views/DashboardView.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/tasting-reports',
      name: 'tasting-reports',
      component: () => import('@/views/reports/TastingReportsView.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/tasting-reports/create/:id',
      name: 'create-tasting-report',
      component: () => import('@/views/reports/CreateTastingReport.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/:pathMatch(.*)*',
      name: 'not-found',
      component: () => import('@/views/NotFound.vue'),
      meta: {
        requiresAuth: false,
      },
    },
  ],
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const authStore = useAuthStore()
    const response = await authStore.checkAuthMe(
      localStorage.getItem('authToken'),
    )
    const isLoggedIn = response.ok

    if (!isLoggedIn) {
      router.push('/')
    }
  }
  next()
})

export default router
