const xanoApiUrl = import.meta.env.VITE_XANO_API_URL

async function makeRequest(url, method, data, auth) {
  const headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
  }
  if (auth) {
    headers.Authorization = `Bearer ${auth}`
  }

  return fetch(url, {
    method: method,
    headers: headers,
    body: data ? JSON.stringify(data) : null,
  })
}

async function authMe(auth) {
  return await makeRequest(xanoApiUrl + '/auth/me', 'GET', null, auth)
}

async function getStores(auth) {
  return await makeRequest(xanoApiUrl + '/stores', 'GET', null, auth)
}

async function getEmployees(auth) {
  return await makeRequest(xanoApiUrl + '/employees', 'GET', null, auth)
}

async function createTastingReport(auth) {
  return await makeRequest(xanoApiUrl + '/tasting_reports', 'POST', null, auth)
}

async function updateTastingReport(auth, id, data) {
  return await makeRequest(
    xanoApiUrl + `/tasting_reports/${id}`,
    'PUT',
    data,
    auth,
  )
}

async function getTastingReports(auth) {
  return await makeRequest(xanoApiUrl + '/tasting_reports', 'GET', null, auth)
}

async function getTastingReportById(auth, id) {
  return await makeRequest(
    xanoApiUrl + `/tasting_reports/${id}`,
    'GET',
    null,
    auth,
  )
}

async function confirmTastingReport(auth, data) {
  // console.log('confirmTastingReport auth', auth)
  // console.log('confirmTastingReport data', data)
  return await makeRequest(
    xanoApiUrl + `/confirm_tasting_report`,
    'POST',
    data,
    auth,
  )
}

async function sendMagicLink(data) {
  return await makeRequest(xanoApiUrl + '/send_magic_link', 'POST', data)
}

async function checkMagicLink(data) {
  return await makeRequest(xanoApiUrl + '/check_magic_link', 'POST', data)
}

export {
  authMe,
  getStores,
  getEmployees,
  createTastingReport,
  getTastingReports,
  getTastingReportById,
  updateTastingReport,
  confirmTastingReport,
  sendMagicLink,
  checkMagicLink,
}
